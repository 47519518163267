import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import GenAiHero from '@/components/genai-productionize-2024/genai-hero';
import { PortableText } from '@portabletext/react';
import SEO from '@/components/seo';

const serializers = {
  marks: {
    link: ({ children, mark }) => {
      const target = '_blank';
      const rel = 'noopener noreferrer';
      return (
        <a
          href={mark.href}
          target={target}
          rel={rel}
          className="text-blue-800 underline transition-all duration-300 hover:text-purply-blue"
        >
          {children}
        </a>
      );
    },
  },
  list: {
    bullet: ({ children }) => (
      <ul className="list-inside list-disc">{children}</ul>
    ),
    number: ({ children }) => (
      <ol className="list-inside list-decimal">{children}</ol>
    ),
  },
  listItem: {
    bullet: ({ children }) => <li>{children}</li>,
    number: ({ children }) => <li>{children}</li>,
  },
};

const titlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <div className="title-hl title-b">{children}</div>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const GenAi = ({ data }) => {
  useEffect(() => {
    const links = document.querySelectorAll('.summit-session-info a');
    links.forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });
  }, []);

  const {
    seo,
    genaiHeroSection: { _rawTitleV2, text1, cost, text2, text3, presenters },
    genaiHeroSection,
    genAiAbout,
    summitSpeakers,
    summitSessions,
    formSuccessMessageTitle,
    tag,
  } = data.sanityGenAi;

  return (
    <Layout>
      <GenAiHero heroData={genaiHeroSection} />

      <div className="m-auto mx-auto h-full max-w-[1440px] px-4 sm:px-10 lg:px-20">
        <div className="py-[100px]">
          <div className="summit-speakers-heading">
            <h2 className="mb-[30px] text-4xl font-medium not-italic leading-[64px] text-[#070707] lg:mb-[67px] lg:text-[36px] xl:text-[44px]">
              {summitSpeakers.title}
            </h2>
          </div>
          <div className="summit-speakers-section grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {summitSpeakers.speakers.map(
              ({ title, subtitle, role, link, image, companyImage }) => {
                const imageData = getImage(image.asset.gatsbyImageData);
                return (
                  <a
                    href={link}
                    className="summit-speaker relative flex items-center gap-6 rounded-3xl border border-[#EDF0FF] p-6"
                    key={title}
                    target="_blank"
                  >
                    <div
                      className="relative min-w-[95px] overflow-hidden rounded-full"
                      style={{
                        backgroundImage: `url(${image.asset.gatsbyImageData.images.fallback.src})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '95px',
                        height: '95px',
                      }}
                    >
                      <div className="speaker-overlay absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(39,120,178,0)] opacity-0 transition duration-300 ease-in-out">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M17.7083 17.8397H14.042V12.0962C14.042 10.7265 14.0182 8.96392 12.134 8.96392C10.2499 8.96392 9.93298 10.4552 9.93298 11.9963V17.8397H6.26675V6.0292H9.78538V7.64416H9.83313C10.1848 7.04289 10.6949 6.54581 11.307 6.21153C11.9191 5.87508 12.6094 5.71228 13.3062 5.73833C17.0223 5.73833 17.7083 8.18248 17.7083 11.3625V17.8397Z"
                            fill="#EDF0FF"
                          />
                          <path
                            d="M2.12949 4.4164C1.70838 4.4164 1.29813 4.29268 0.946485 4.05825C0.59701 3.82382 0.323508 3.49171 0.16288 3.10316C8.07466e-05 2.71461 -0.0411616 2.28482 0.0413231 1.8724C0.123808 1.45998 0.325678 1.08011 0.623058 0.782733C0.920437 0.485353 1.3003 0.283483 1.71273 0.200998C2.12515 0.118513 2.55277 0.161926 2.94131 0.322555C3.32986 0.483183 3.66197 0.756685 3.8964 1.10616C4.13083 1.45563 4.25456 1.86806 4.25456 2.28917C4.25456 2.85353 4.03098 3.39403 3.63158 3.79343C3.23218 4.19283 2.69169 4.4164 2.12732 4.4164H2.12949Z"
                            fill="#EDF0FF"
                          />
                          <path
                            d="M3.96152 6.0292H0.290948V17.8375H3.96152V6.0292Z"
                            fill="#EDF0FF"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-2xl font-bold text-[#070707]">
                        {title}
                      </p>
                      <div>
                        <p className="text-base font-normal leading-6">
                          <div className="speaker-company max-w-[82px]">
                            <GatsbyImage
                              image={getImage(
                                companyImage.asset.gatsbyImageData,
                              )}
                              alt={title}
                            />
                          </div>
                        </p>
                        <p className="max-w-[270px] text-base font-normal leading-6">
                          {role}
                        </p>
                      </div>
                    </div>
                  </a>
                );
              },
            )}
            <div className="summit-speaker relative flex items-center gap-6 rounded-3xl border border-[#EDF0FF] p-6">
              <div className="relative max-w-[92px] overflow-hidden rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="92"
                  height="92"
                  viewBox="0 0 92 92"
                  fill="none"
                >
                  <rect width="92" height="92" rx="46" fill="#EDF0FF" />
                  <path
                    d="M46.3573 51.9742C40.0444 51.9742 34.4304 55.0223 30.8562 59.7526C30.0869 60.7707 29.7023 61.2797 29.7149 61.9677C29.7246 62.4992 30.0584 63.1697 30.4766 63.4979C31.0179 63.9226 31.768 63.9226 33.2683 63.9226H59.4464C60.9466 63.9226 61.6968 63.9226 62.2381 63.4979C62.6563 63.1697 62.99 62.4992 62.9998 61.9677C63.0123 61.2797 62.6277 60.7707 61.8584 59.7526C58.2843 55.0223 52.6702 51.9742 46.3573 51.9742Z"
                    stroke="white"
                    stroke-width="3.98281"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M46.3573 46C51.3065 46 55.3187 41.9879 55.3187 37.0387C55.3187 32.0895 51.3065 28.0773 46.3573 28.0773C41.4081 28.0773 37.396 32.0895 37.396 37.0387C37.396 41.9879 41.4081 46 46.3573 46Z"
                    stroke="white"
                    stroke-width="3.98281"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div>
                  <p className="text-base font-semibold not-italic leading-[normal] text-[#8E9099]">
                    More speakers coming soon
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-solid border-t-[rgba(0,0,0,0.20)]">
        <div className="m-auto grid h-full max-w-[1440px] grid-cols-1 px-4 py-20 sm:px-10 md:grid-cols-2 md:py-36 lg:px-20 lg:px-4">
          <div className="genai-about-heading">
            <h2 className="mb-[30px] text-4xl font-medium not-italic leading-[64px] text-[#070707] lg:mb-[67px] lg:text-[36px] xl:text-[44px]">
              {genAiAbout.title}
            </h2>
          </div>
          <p className="text-lg font-normal leading-[150%] tracking-[0.5px] md:text-xl md:leading-[38.894px]">
            {genAiAbout.subtitle}
          </p>
        </div>
      </div>

      <div
        id="watch-on-demand"
        className="bg-[#EDF0FF] py-[60px] lg:py-[100px]"
      >
        <div className="summit-sessions-heading m-auto mx-auto h-full max-w-[1440px] px-4 sm:px-10 lg:px-20 lg:px-4">
          <h2 className="mb-[30px] text-4xl font-medium not-italic leading-[64px] text-[#070707] lg:mb-[67px] lg:text-[36px] xl:text-[44px]">
            {summitSessions.title}
          </h2>
        </div>
        <div className="summit-sessions-section m-auto mx-auto grid h-full max-w-[1440px] grid-cols-1 gap-6 px-4 sm:px-10 md:grid-cols-2 lg:gap-10 lg:px-20 lg:px-4">
          <div className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey p-6">
            <h3 className="mb-4 min-h-[60px] text-base font-bold text-smoky-black lg:text-2xl">
              Keynote: GenAI at Enterprise Scale
            </h3>
            <img
              style={{ width: '100%', margin: 'auto', display: 'block' }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/TaqhahEUjnYq4tDyPFFZjb.jpg"
              data-uuid="TaqhahEUjnYq4tDyPFFZjb"
              data-v="4"
              data-type="inline"
            />
            <p className="block pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px]">
              Get an overview of the technologies, frameworks, and
              organizational design strategies executives can leverage to scale
              GenAI within the enterprise.
              <br />
              <br />
              • Ya Xu, VP Engineering, Head of Data and AI, LinkedIn
              <br />• Vikram Chatterji, CEO, Galileo
            </p>
          </div>
          <div className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey p-6">
            <h3 className="mb-4 min-h-[60px] text-base font-bold text-smoky-black lg:text-2xl">
              Executive Roundtable: The Enterprise AI Adoption Journey
            </h3>
            <img
              style={{ width: '100%', margin: 'auto', display: 'block' }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/JigGSsquz8uxmvi8eWsYpJ.jpg"
              data-uuid="JigGSsquz8uxmvi8eWsYpJ"
              data-v="4"
              data-type="inline"
            />
            <p className="block pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px]">
              How have organizations successfully adopted enterprise GenAI? Join
              a candid discussion with enterprise executives about the key
              challenges and opportunities they experienced on their GenAI
              journey.
              <br />
              <br />
              • Anpuam Singh, VP of Engineering, Roblox
              <br />
              • Surojit Chatterjee, Founder & CEO, Ema
              <br />• Rama Mahajanam, Sr. Director of ML, Comcast
            </p>
          </div>
          <div className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey p-6">
            <h3 className="mb-4 min-h-[60px] text-base font-bold text-smoky-black lg:text-2xl">
              Panel: Enough Strategy, Let's Build! How to Productionize GenAI
            </h3>
            <img
              style={{ width: '100%', margin: 'auto', display: 'block' }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/Mpcpdc474Xv73hgZVNpKmW.jpg"
              data-uuid="Mpcpdc474Xv73hgZVNpKmW"
              data-v="4"
              data-type="inline"
            />
            <p className="block pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px]">
              A practical, technical session providing architecture and tool
              insights from leading AI practicioners. Learn about successful
              practices and what pitfalls to avoid during GenAI development.
              <br />
              <br />
              • Taranveer Singh, AI/ML Tech Lead, Chegg
              <br />
              • Aman Tyagi, AI/NLP Research Scientist, Procter & Gamble
              <br />• Anand Iyer, Group Product Manager, Google Cloud GenAI
            </p>
          </div>
          <div className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey p-6">
            <h3 className="mb-4 min-h-[60px] text-base font-bold text-smoky-black lg:text-2xl">
              Evaluation is All You Need: Practical Tips for GenAI System
              Evaluation
            </h3>
            <img
              style={{ width: '100%', margin: 'auto', display: 'block' }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/ywJKSsUuAnMSam7RBoy7UJ.jpg"
              data-uuid="ywJKSsUuAnMSam7RBoy7UJ"
              data-v="4"
              data-type="inline"
            />
            <p className="block pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px]">
              How are you evaluating your GenAI? Learn practical tips and
              frameworks to help you evaluate and improve your GenAI.
              <br />
              <br />• Craig Wiley, Senior Director of AI
            </p>
          </div>
          <div className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey p-6">
            <h3 className="mb-4 min-h-[60px] text-base font-bold text-smoky-black lg:text-2xl">
              Demo: End-to-End Evaluations
            </h3>
            <img
              style={{ width: '100%', margin: 'auto', display: 'block' }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/ihvqMSTQXZz1hEAKJRKW2c.jpg"
              data-uuid="ihvqMSTQXZz1hEAKJRKW2c"
              data-v="4"
              data-type="inline"
            />
            <p className="block pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px]">
              See enterprise GenAI in action! This hands-on demo showcases how
              to rapidly build and deploy a functional chatbot for customer
              support using the latest GenAI technologies.
              <br />
              <br />• Atin Sanyal, Co-Founder & CTO, Galileo
            </p>
          </div>
          <div className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey p-6">
            <h3 className="mb-4 min-h-[60px] text-base font-bold text-smoky-black lg:text-2xl">
              LLMOps: Views From Across the Stack
            </h3>
            <img
              style={{ width: '100%', margin: 'auto', display: 'block' }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/noXa2y9t9AFN342Y7j3hob.jpg"
              data-uuid="noXa2y9t9AFN342Y7j3hob"
              data-v="4"
              data-type="inline"
            />
            <p className="block pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px]">
              What's better than real case studies? Join CxOs from leading
              LLMOps providers to hear their perspectives on the emerging
              enterprise GenAI stack, from orchestration to evaluation,
              inference, retrieval and more.
              <br />
              <br />
              • Bob van Luijt, CEO, Weaviate
              <br />
              • Jerry Liu, CEO, LlamaIndex
              <br />
              • Devvret Rishi, CEO, Predibase
              <br />• Lin Qiao, CEO, Fireworks AI
            </p>
          </div>
          <div className="rounded-lg border border-purple-100 bg-gradient-to-tr from-white via-white/50 to-bluey p-6">
            <h3 className="mb-4 min-h-[60px] text-base font-bold text-smoky-black lg:text-2xl">
              Closing Remarks: The Path Ahead
            </h3>
            <img
              style={{ width: '100%', margin: 'auto', display: 'block' }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/vBGGmoyGKuefcL5jmjAoJz.jpg"
              data-uuid="vBGGmoyGKuefcL5jmjAoJz"
              data-v="4"
              data-type="inline"
            />
            <p className="block pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px]">
              Let's cover key takeaways from GenAI Productionize and make some
              predictions on the future of enterprise GenAI.
              <br />
              <br />• Vikram Chatterji, CEO, Galileo
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query GenAiQuery {
    sanityGenAi {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      genaiHeroSection {
        _rawTitleV2(resolveReferences: { maxDepth: 10 })
        _rawSubTitleV2(resolveReferences: { maxDepth: 10 })
        logo {
          asset {
            gatsbyImageData
          }
        }
        text1
        cost
        text2
        text3
        ctaLinksV2 {
          ... on SanityExternalLink {
            _type
            label
            url
          }
          ... on SanityInternalLink {
            _type
            label
            slug {
              current
            }
          }
        }
        presenters {
          asset {
            url
          }
        }
      }
      genAiAbout {
        title
        subtitle
      }
      summitSpeakers {
        title
        speakers {
          title
          subtitle
          role
          link
          image {
            asset {
              gatsbyImageData
            }
          }
          companyImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      summitSessions {
        title
      }
      tag
    }
  }
`;

export default GenAi;

export const Head = ({ data }) => {
  const { seo } = data.sanityGenAi;

  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
