import React from 'react';
import { PortableText } from '@portabletext/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Logos } from '@/components/genai-productionize-2024/logos';

const titlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <div className="title-hl title-w">{children}</div>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const GenAiHero = ({ heroData }) => {
  const {
    logo,
    _rawTitleV2,
    _rawSubTitleV2,
    text1,
    cost,
    text2,
    text3,
    ctaLinksV2,
    presenters,
  } = heroData;

  const logoImage = getImage(logo.asset);

  return (
    <div className="bg-[#070707] bg-[url(/genai-hero-bg.svg)] bg-[length:150%] bg-[bottom_center] bg-no-repeat md:bg-[length:100%] lg:bg-contain">
      <div className="relative mx-auto h-full w-full max-w-[1440px] bg-[1440px] bg-[bottom_center] bg-no-repeat px-4 sm:px-10 lg:px-20 lg:px-4">
        <div className="lg:py-26 py-12 md:py-24 lg:pb-[72px] lg:pt-16">
          {logoImage && (
            <div className="mb-4 text-center">
              <GatsbyImage image={logoImage} alt="Logo" />
            </div>
          )}
          <div>
            {' '}
            <h1 className="keep-inline text-center text-4xl leading-tight text-white md:text-[48px] lg:text-[56px] xl:text-[64px]">
              <PortableText
                value={_rawTitleV2}
                components={titlePortableText}
              />
            </h1>
            {_rawSubTitleV2 && (
              <p className="mx-auto mt-4 max-w-[650px] text-center font-light text-white/80 md:text-lg lg:max-w-[550px] xl:max-w-[550px]">
                <PortableText value={_rawSubTitleV2} />
              </p>
            )}
            <div className="pb-6 text-center">
              <div className="event-details mb-11 mt-8 inline-flex hidden rounded-[28px] border-[0.728px] border-solid border-[#373737] bg-[rgba(255,255,255,0.05)] px-[25px] py-[5px] text-[13px] font-normal not-italic text-white sm:text-[13px] lg:text-[18px]">
                <span className="border-r-[0.73px] border-solid border-r-[#373737] pr-3 sm:pr-3 lg:pr-5">
                  {text1}
                </span>
                <span className="border-r-[0.73px] border-solid border-r-[#373737] px-3 sm:px-3 lg:px-5">
                  {cost}
                </span>
                <span className="border-r-[0.73px] border-solid border-r-[#373737] px-3 sm:px-3 lg:px-5">
                  {text2}
                </span>
                <span className="pl-3 sm:pl-3 lg:pl-5">{text3}</span>
              </div>
            </div>
            <div className="w-full text-center">
              {' '}
              {ctaLinksV2 &&
                ctaLinksV2.map((link, index) => {
                  const buttonClass =
                    'w-[220px] inline-block px-6 py-3.5 text-white bg-primary-600 rounded-lg hover:opacity-80 font-semibold leading-none border-2 border-primary-600 text-center';
                  return link._type === 'externalLink' ? (
                    <a className={buttonClass}>Watch On-Demand</a>
                  ) : (
                    <a className={buttonClass} href="#watch-on-demand">
                      Watch On-Demand
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-t border-solid border-y-[#2A2A2A] bg-[rgba(7,7,7,0.80)] py-[31px]">
        <div className="text-center text-base font-bold font-light uppercase">
          <Logos logos={presenters} />
        </div>
      </div>
    </div>
  );
};

export default GenAiHero;
